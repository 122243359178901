.bubble {
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.bubble svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 100px;
}
